/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SVG from "react-inlinesvg";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import AddList from "../../../assets/media/icons/menu_icons/Add-List.svg";
import AlertIndicator from "../../../assets/media/icons/menu_icons/Alerts-Indicator.svg";
import Alerts from "../../../assets/media/icons/menu_icons/Alerts.svg";
import Alerts1 from "../../../assets/media/icons/menu_icons/Alerts1.svg";
import Dashboard from "../../../assets/media/icons/menu_icons/Dashboard.svg";
import InventorySVG from "../../../assets/media/icons/menu_icons/Inventory.svg";
import ReportSVG from "../../../assets/media/icons/menu_icons/Reports.svg";
import SettingSVG from "../../../assets/media/icons/menu_icons/Settings.svg";
import faqSVG from "../../../assets/media/icons/menu_icons/faq.svg";
import LogoutSVG from "../../../assets/media/icons/menu_icons/logout_white.svg";
import Plug from "../../../assets/media/icons/menu_icons/plug.svg";
import SalesSVG from "../../../assets/media/icons/menu_icons/salesNew.svg";
import TX_TRADESVG from "../../../assets/media/icons/menu_icons/tx-trade.svg";
import TXAccountSVG from "../../../assets/media/icons/menu_icons/txAccount.svg";
import TXPaySVG from "../../../assets/media/icons/menu_icons/txPayNew.svg";

import { upperCase } from "lodash";
import TixLogo from "../../../assets/media/icons/menu_icons/TixLogo";
import SubscribeIcon from "../../../assets/media/icons/other_icons/user.svg";
import ActivityLog from "../../../assets/media/icons/standard_icons/activity-log.svg";
import { LoadImages } from "../../../helpers/AssetHelpers";
import { addCommaInNumber } from "../../../helpers/Functions";
import { QUERIES } from "../../../helpers/const";
import {
  getMarkAsPinned,
  getMarkAsUnpinned,
} from "../../../pages/notifications/core/requests";
import { LayoutContext } from "../../core/LayoutProvider";
import PusherSetup from "../../core/PusherSetup";
import { getNotificationListing } from "../../core/requests";
import { KTSVG } from "../../ui/KTSVG";
import AsideMenuItem from "./AsideMenuItem";
import { ShowSubmenu } from "./ShowSubmenu";

const AsideDefault = () => {
  const [open, setOpen] = useState(false);
  const [menuopen, setmenuopen] = useState(false); // only for mobile
  const [showMenu, setShowMenu] = useState(false); // show all menu instead of load after another
  const layout = useContext(LayoutContext);
  let tabValue = layout.tabValue;
  const catMenu = useRef<any>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const authUser = layout.authUser;
  const access = layout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );

  const notificationStatuts =
    layout.allowedAccess?.account_info?.is_read_new_notifications;
  const isGodAdmin = layout.allowedAccess?.account_info?.is_god_admin;

  const closeOpenMenus = (e: any) => {
    if (catMenu.current && open && !catMenu.current.contains(e.target)) {
      setOpen(false);
    }

    if (layout?.notificationId && !catMenu?.current?.contains(e.target)) {
      layout?.setNotificationId(undefined);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  useEffect(() => {
    setTimeout(() => {
      setShowMenu(true);
    }, 1000);
  }, []);

  useEffect(() => {
    setOpen(false); // Close the navigation panel
  }, [pathname]);

  // GET NOTIFICATIONS LISTING
  const {
    isLoading: notificationsLoading,
    data: listingData,
    refetch,
  } = useQuery(
    [
      `${QUERIES.NOTIFICATIONS_LISTING}`,
      tabValue,
      layout.newNotification,
      layout.notificationId,
    ],
    () =>
      getNotificationListing({
        tab_name: tabValue,
        ...(userSpecific && { team_member: [authUser?.id] }),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled:
        layout.newNotification !== undefined ||
        layout.notificationId !== undefined,
      onError: (err) => {},
    }
  );

  useEffect(() => {
    if (listingData?.data?.activities) {
      layout?.setActivities(
        listingData?.data?.activities?.map((activity: any) => {
          return {
            ...activity,
          };
        })
      );
    }
  }, [listingData, tabValue, listingData?.data?.activities]);

  useEffect(() => {
    if (listingData?.data?.notifications) {
      layout?.setNotifications(
        listingData?.data?.notifications?.map((notification: any) => {
          return {
            ...notification,
          };
        })
      );
    }
  }, [listingData, tabValue]);

  const handleUnpin = (e: any, id: any) => {
    getMarkAsUnpinned([id])
      .then((response) => {
        if (response.data.status === true) {
          layout.setNotificationId(layout.notificationId + 1);
          // layout.setNotifications(layout.notifications.map((notification: any) => {
          //   if (notification.selected) {
          //     notification.selected = false
          //     return {
          //       ...notification,
          //       is_pinned: false
          //     }
          //   }
          //   else {
          //     return notification
          //   }
          // }))
        }
      })
      .catch((error) => {});
  };

  const handlePin = (e: any, id: any) => {
    getMarkAsPinned([id])
      .then((response) => {
        if (response.data.status === true) {
          layout.setNotificationId(id);
          layout.setNotifications(
            layout.notifications.map((notification: any) => {
              if (notification.selected) {
                notification.selected = false;
                return {
                  ...notification,
                  is_pinned: true,
                };
              } else {
                return notification;
              }
            })
          );
        }
      })
      .catch((error) => {});
  };

  // MEMOIZED ACCESS
  const memoizedAccess = useMemo(() => access, [access]);

  // NAVIGATE TO DASHBOARD MODULE
  const handleClick = () => {
    navigate("../", { replace: true });
  };

  const closeOpenMainMenu = (e: any) => {
    if (catMenu.current && menuopen && !catMenu.current.contains(e.target)) {
      setmenuopen(!menuopen);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMainMenu);
    return () => {
      document.removeEventListener("mousedown", closeOpenMainMenu);
    };
  }, [menuopen]);

  // Submenu  design fixed
  useEffect(() => {
    const menuItems = document.querySelectorAll(
      "[data-menu-id]"
    ) as NodeListOf<HTMLElement>;
    const menuUl = document.querySelector(".menu-ul") as HTMLElement;
    const MenuWrap = document.querySelector(
      ".notification_class"
    ) as HTMLElement;
    let currentSubmenu: HTMLElement | null = null;
    let activeMenuItem: HTMLElement | null = null;

    const updateSubmenuPosition = () => {
      if (currentSubmenu && activeMenuItem) {
        const { offsetTop } = activeMenuItem;
        const scrollTop = menuUl.scrollTop;
        const scrollLeft = MenuWrap.offsetWidth;

        currentSubmenu.style.top = `${offsetTop - scrollTop}px`;
        currentSubmenu.style.left = `${scrollLeft}px`;
      }
    };

    const handleClick = (event: Event) => {
      event.stopPropagation();
      const menuItem = event.currentTarget as HTMLElement;
      const menuId = menuItem.getAttribute("data-menu-id");

      if (currentSubmenu) {
        currentSubmenu.style.display = "none";
        if (currentSubmenu.getAttribute("data-submenu-id") === menuId) {
          currentSubmenu = null;
          activeMenuItem = null;
          return;
        }
      }

      if (menuId) {
        const submenuToShow = document.querySelector(
          `[data-submenu-id="${menuId}"]`
        ) as HTMLElement;
        if (submenuToShow) {
          const { offsetTop } = menuItem;
          const scrollTop = menuUl.scrollTop;
          const scrollLeft = MenuWrap.offsetWidth;

          submenuToShow.style.top = `${offsetTop - scrollTop}px`;
          submenuToShow.style.left = `${scrollLeft}px`;

          submenuToShow.style.display = "block";
          currentSubmenu = submenuToShow;
          activeMenuItem = menuItem;

          updateSubmenuPosition();
        }
      }
    };

    const handleBodyClick = () => {
      if (currentSubmenu) {
        currentSubmenu.style.display = "none";
        currentSubmenu = null;
        activeMenuItem = null;
      }
    };

    menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", handleClick);
    });

    if (menuUl) {
      menuUl.addEventListener("scroll", updateSubmenuPosition);
    }

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      menuItems.forEach((menuItem) => {
        menuItem.removeEventListener("click", handleClick);
      });
      if (menuUl) {
        menuUl.removeEventListener("scroll", updateSubmenuPosition);
      }
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [layout.allowedAccess?.allowed_access]);

  return (
    <>
      <PusherSetup refetch={() => layout.setIsPusherCall(true)} />
      <div
        className={`flex fixed z-[99999999] notification_class md:pointer-events-auto pointer-events-none`}
        ref={catMenu}
      >
        <div
          className={`${
            open ? "w-[11.7rem] open-menu" : "w-[3.75rem] close-menu"
          } menu-wrap h-screen px-2.5 pt-5 relative duration-200 bg-violet-600 overflow-x-auto scrollbar-none scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full`}
        >
          <div
            className="flex md:hidden gap-x-4 items-center cursor-pointer logo pointer-events-auto"
            // onClick={handleClick}
            onClick={() => {
              setOpen(false);
              setmenuopen(menuopen ? false : true);
              layout.setNotificationId(undefined);
            }}
          >
            <TixLogo className={`cursor-pointer duration-500`} />
          </div>
          <div
            className="md:flex pl-[.1875rem] gap-x-4 items-center cursor-pointer logo hidden"
            onClick={handleClick}
          >
            <TixLogo
              className={`cursor-pointer duration-500 w-[2.125rem] h-[1.9375rem]`}
            />
          </div>

          <ul
            className={`menu-ul pt-4 ${
              menuopen ? "active-menu" : ""
            } pointer-events-auto ${
              !showMenu ? `hidden` : ``
            } bg-violet-600 overflow-auto scrollbar-none scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full`}
          >
            <li
              className="flex rounded p-2 mb-2 cursor-pointer overflow-hidden hover:bg-light-white text-gray-300 text-sm15 items-center gap-x-3 hover:bg-indigo-700 transition"
              onClick={() => setOpen((prev: any) => !prev)}
            >
              <img
                src={LoadImages(
                  `menu_icons/${
                    open === false ? "menu-open.svg" : "menu-close.svg"
                  }`
                )}
                alt=""
                className="w-6 h-3"
              />
              <span
                className={`${
                  !open ? "invisible" : ""
                } origin-left duration-200 text-white font-semibold text-sm13`}
              >
                {open ? "Close" : <span className="invisible">&nbsp</span>}
              </span>
            </li>
            <>
              <div
                className="group user_logo h-10 flex items-center relative mb-2.5 w-full gap-x-2.5"
                data-tip
                id="par_id"
              >
                <div className="w-10 h-[2.8125rem] flex items-center justify-center text-white bg-avatarShape bg-cover text-sm15 font-semibold min-w-[2.5rem]">
                  {upperCase(
                    layout.allowedAccess?.account_info?.firstname?.charAt(0)
                  )}
                  {upperCase(
                    layout.allowedAccess?.account_info?.surname?.charAt(0)
                  )}
                </div>
                {open && (
                  <span className="text-sm13 uppercase origin-left duration-400 text-white font-medium leading-4 text-truncate">
                    {layout.allowedAccess?.account_info?.firstname}{" "}
                    {layout.allowedAccess?.account_info?.surname}
                  </span>
                )}
              </div>
              <Tooltip
                anchorId={"par_id"}
                html={`<div><h2 class="name">${`${layout.allowedAccess?.account_info?.firstname} ${layout.allowedAccess?.account_info?.surname}`}</h2> 
                  <!-- <span class="type">Accounts</span> -->
                  </div>`}
                place="right"
                variant="light"
                className="   !bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
                positionStrategy="fixed"
              />
              {/* Notifications */}
              <Suspense fallback={<div>Loading...</div>}>
                <AsideMenuItem
                  state={open}
                  title="Notifications"
                  svgName={notificationStatuts ? AlertIndicator : Alerts1}
                  path={"#"}
                  width={"w-5"}
                  height={"h-[1.5625rem]"}
                />
              </Suspense>

              {/* Dashboard */}
              <Suspense fallback={<div>Loading...</div>}>
                <AsideMenuItem
                  state={open}
                  title="Dashboard"
                  svgName={Dashboard}
                  path={layout.isTxTradeUser === 1 ? "/dashboard" : "/"}
                  width={"w-[1.375rem]"}
                  height={"h-[1.0625rem]"}
                />
              </Suspense>

              {/* Add Inventory */}

              {!isGodAdmin && (
                <Suspense fallback={<div>Loading...</div>}>
                  {memoizedAccess &&
                    memoizedAccess?.find(
                      (item: any) => item.id === "inventory" && item.status
                    ) && (
                      <Fragment key={`inventory`}>
                        <AsideMenuItem
                          state={open}
                          title="Add Inventory"
                          svgName={AddList}
                          path="/add-inventory"
                          width={"w-[1.375rem]"}
                          height={"h-[1.125rem]"}
                        />
                      </Fragment>
                    )}
                </Suspense>
              )}

              {/* Inventory */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "inventory" && item.status
                  ) && (
                    <Fragment key={`inventory`}>
                      <AsideMenuItem
                        state={open}
                        title="Inventory"
                        svgName={InventorySVG}
                        path="/inventory"
                        width={"w-[1.375rem]"}
                        height={"h-[1.125rem]"}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* Sales */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "shipping" && item.status
                  ) && (
                    <Fragment key={`shipping`}>
                      {isGodAdmin &&
                      memoizedAccess?.find(
                        (item: any) =>
                          item.id === "instruction_letters" && item.status
                      ) ? (
                        <AsideMenuItem
                          state={open}
                          title="Sales"
                          svgName={SalesSVG}
                          path={"/sales"}
                          // showSubmenu={[
                          //   {
                          //     name: "Sales",
                          //     path: "/sales",
                          //   },
                          //   {
                          //     name: "Instruction Letter",
                          //     path: "/instruction-letter",
                          //   },
                          // ]}
                          width={"w-[1.375rem]"}
                          height={"h-[1.125rem]"}
                        />
                      ) : (
                        <AsideMenuItem
                          state={open}
                          title="Sales"
                          svgName={SalesSVG}
                          path="/sales"
                          width={"w-[1.375rem]"}
                          height={"h-[1.125rem]"}
                        />
                      )}
                    </Fragment>
                  )}
              </Suspense>

              {/* Reports */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "reports" && item.status
                  ) && (
                    <Fragment key="reports">
                      <AsideMenuItem
                        state={open}
                        title="Reports"
                        svgName={ReportSVG}
                        path="/reports"
                        width={"w-[1.375rem]"}
                        height={"h-[1.125rem]"}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* API Connector */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "api-connector" && item.status
                  ) && (
                    <Fragment key="API-connector">
                      <AsideMenuItem
                        state={open}
                        title="API-connector"
                        svgName={Plug}
                        path="/API-connector"
                        width={"w-[1.375rem]"}
                        height={"h-[1.125rem]"}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* TX Pay */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "txpay" && item.status
                  ) && (
                    <AsideMenuItem
                      state={open}
                      title="TX Pay"
                      svgName={TXPaySVG}
                      path="/tx-pay"
                      width={"w-[1.375rem]"}
                      height={"h-[1.125rem]"}
                    />
                  )}
              </Suspense>

              {/* TX Accounts */}
              {isGodAdmin &&
                memoizedAccess &&
                memoizedAccess?.find(
                  (item: any) => item.id === "txaccounts" && item.status
                ) && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <AsideMenuItem
                      state={open}
                      title="TX Accounts"
                      svgName={TXAccountSVG}
                      path="/tx-account"
                      width={"w-[1.375rem]"}
                      height={"h-[1.125rem]"}
                    />
                  </Suspense>
                )}

              {/* TX-TRADE */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "txtrade" && item.status
                  ) && (
                    <AsideMenuItem
                      state={open}
                      title="TX Trade"
                      svgName={TX_TRADESVG}
                      path={layout.isTxTradeUser === 1 ? "/" : "/tx-trade"}
                      width={"w-[1.4375rem]"}
                      height={"h-[1.1875rem]"}
                      showSubmenu={
                        memoizedAccess &&
                        memoizedAccess?.find(
                          (item: any) => item.id === "txtrade" && item.status
                        ) &&
                        isGodAdmin &&
                        layout?.isTxTradeCustomize
                          ? [
                              {
                                name: "Customise",
                                path: "/tx-trade-customise",
                              },
                            ]
                          : undefined
                      }
                    />
                  )}
              </Suspense>

              {/* Settings */}
              {/* {!isGodAdmin && ( */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "settings" && item.status
                  ) && (
                    <AsideMenuItem
                      state={open}
                      title="Settings"
                      svgName={SettingSVG}
                      path={["/settings", "/instruction-letter"]}
                      width={"w-[1.375rem]"}
                      height={"h-[1.125rem]"}
                      // showSubmenu={[
                      //   {
                      //     name: "Settings",
                      //     path: "/settings",
                      //   },
                      //   {
                      //     name: "Instruction Letter",
                      //     path: "/instruction-letter",
                      //   },
                      // ]}
                    />
                  )}
              </Suspense>

              {/* FAQ */}
              <Suspense fallback={<div>Loading...</div>}>
                <AsideMenuItem
                  state={open}
                  title="FAQ"
                  svgName={faqSVG}
                  path={"https://help.tixstock.com"}
                  width={"w-[1.375rem]"}
                  height={"h-[1.3125rem]"}
                  isExternal={true}
                />
              </Suspense>
            </>

            {/* SUBSCRIBE */}
            <Suspense fallback={<div>Loading...</div>}>
              {memoizedAccess &&
                memoizedAccess?.find(
                  (item: any) => item.id === "subscribers" && item.status
                ) && (
                  <AsideMenuItem
                    state={open}
                    title="Subscribers"
                    svgName={SubscribeIcon}
                    path={""}
                    showSubmenu={[
                      {
                        name: "Personal/company details",
                        path: "/personal-details",
                      },
                      {
                        name: "Wallets & Bank accounts",
                        path: "/wallet-&-bank-details",
                      },
                    ]}
                    width={"w-[1.375rem]"}
                    height={"h-[1.375rem]"}
                  />
                )}
            </Suspense>

            {/* Logout */}
            <Suspense fallback={<div>Loading...</div>}>
              <AsideMenuItem
                state={open}
                title="Logout"
                svgName={LogoutSVG}
                path="/logout"
                width={"w-[1.375rem] rotate-180"}
                height={"h-[1.125rem]"}
              />
            </Suspense>
          </ul>

          {/** Sub menu */}
          <div className="submenu-main">
            <ShowSubmenu
              title="FAQ"
              showSubmenu={[
                {
                  name: "Help center",
                  path: "https://help.tixstock.com",
                  externalLink: true,
                },
                {
                  name: "TX Trade policy",
                  path: "/faq/tx-trade-policy",
                },
              ]}
            />

            <ShowSubmenu
              title="Subscribers"
              showSubmenu={[
                {
                  name: "Personal/company details",
                  path: "/personal-details",
                },
                {
                  name: "Wallets & Bank accounts",
                  path: "/wallet-&-bank-details",
                },
              ]}
            />
          </div>
        </div>
        {layout?.notificationId ? (
          <div className=" w-60 h-screen flex flex-col relative duration-400 bg-violet-600 border-l border-white/10 pointer-events-auto">
            <div className="">
              <div className="flex items-start justify-between p-5 border-b border-white/10">
                <h2 className="text-white text-sm15 mr-2 font-semibold">
                  Notifications & activity
                </h2>
                <button onClick={() => layout.setNotificationId(undefined)}>
                  <i className="fas text-white fa-sharp fa-solid fa-xmark"></i>
                </button>
              </div>
              <div className="flex px-5 py-3 border-b border-white/10">
                <button
                  type="button"
                  className="flex items-center text-sm13 font-medium hover:underline text-white"
                  onClick={() => {
                    navigate("../notifications", { replace: true });
                    layout?.setNotificationId(undefined);
                    let logo: any = document.querySelector(".logo");
                    logo && logo.click();
                  }}
                >
                  <SVG className="w-3.5 mr-2.5" src={Alerts} />
                  View all
                </button>
              </div>
              <div className="p-5 border-b border-white/10">
                <button
                  className="w-full text-left mb-3 py-2.5 px-4 text-xs font-medium bg-green-600/50 hover:bg-green-600 border border-green-600 rounded text-white transition"
                  onClick={() => layout.setTabValue("notification")}
                >
                  <span className="min-w-[1rem] text-center inline-block mr-3.5 text-sm15">
                    {listingData?.data?.new_notification
                      ? addCommaInNumber(listingData?.data?.new_notification)
                      : ""}
                  </span>
                  {listingData?.data?.new_notification > 1
                    ? "New notifications"
                    : "New notification"}
                </button>
                <button
                  className="w-full text-left py-2.5 px-4 bg-violet-500 text-xs font-medium bg-indigo-500/50 hover:bg-indigo-500 border border-indigo-500 rounded text-white transition"
                  onClick={() => layout.setTabValue("activity")}
                >
                  <SVG className="w-4 inline-block mr-3.5" src={ActivityLog} />
                  {layout?.tabValue === "activity" &&
                  layout?.activities?.length > 1
                    ? "Activity logs"
                    : "Activity log"}
                </button>
              </div>
            </div>

            {notificationsLoading ? (
              <div className="text-center text-white py-10 opacity-40">
                {/* Loading... */}
                <KTSVG
                  className=" flex items-center justify-center"
                  svgClassName="w-4 h-4 animate-spin"
                  path="other_icons/white_process.svg"
                />
              </div>
            ) : (
              <div className="notification-list overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                <ul>
                  {layout?.tabValue === "notification" ||
                  listingData?.data?.notifications?.length > 0 ? (
                    listingData?.data?.notifications.map(
                      (notification: any, index: any) => (
                        <li
                          className={`pt-2 px-5 transition hover:bg-violet-100/10 ${
                            notification.is_pinned ? "pinned" : ""
                          } ${!notification.is_pinned ? "unpinned" : ""}`}
                        >
                          {notification.is_pinned ? (
                            <>
                              <div className="pb-2 border-b border-white/10 text-xxs font-medium cursor-pointer inner-pin">
                                <Tooltip
                                  anchorId={`record-unpin-tooltip${index}`}
                                  content="Mark as Unpinned"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                />
                                <div
                                  className="text-sky-blue-500 mb-1"
                                  id={`record-unpin-tooltip${index}`}
                                  onClick={(e: any) =>
                                    handleUnpin(e, notification.id)
                                  }
                                >
                                  <KTSVG
                                    className="inline-flex fill-sky-blue-500 text-center mr-1"
                                    svgClassName="w-[.4375rem] h-2.5"
                                    path="standard_icons/pin-icon.svg"
                                  />
                                  <span className="px-1">Pinned</span>
                                  {moment(notification.created_at).format(
                                    "DD MMM"
                                  )}
                                </div>
                                <div
                                  className="text-white"
                                  dangerouslySetInnerHTML={{
                                    __html: notification.text,
                                  }}
                                ></div>
                              </div>
                            </>
                          ) : notification.is_new ? (
                            <>
                              <div className="pb-2 border-b text-xxs font-medium border-white/10 cursor-pointer inner-pin">
                                <Tooltip
                                  anchorId={`record-pin-tooltip${index}`}
                                  content="Mark as Pinned"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                />
                                <div className="group">
                                  <div
                                    className="group-hover:opacity-100 group-hover:visible group-hover:inline-flex opacity-0 invisible hidden overflow-hidden items-center mb-1"
                                    id={`record-pin-tooltip${index}`}
                                    onClick={(e: any) =>
                                      handlePin(e, notification.id)
                                    }
                                  >
                                    <KTSVG
                                      className="inline-flex fill-sky-blue-500 text-center mr-1"
                                      svgClassName="w-[.5rem] h-[.625rem]"
                                      path="standard_icons/pin-icon.svg"
                                    />
                                    <span className="px-1 text-sky-blue-500">
                                      Pin To Top
                                    </span>
                                    <span className="text-gray-500">
                                      {moment(notification.created_at).format(
                                        "DD MMM"
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    className="flex items-center mr-1 mb-1 text-green-600 group-hover:hidden"
                                    id={`record-pin-tooltip${index}`}
                                  >
                                    <span className="w-2 h-2 rounded-full bg-green-600 mr-1.5"></span>
                                    <span className="px-1 ">New</span>
                                    {moment(notification.created_at).format(
                                      "DD MMM"
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="text-white pt-1"
                                  dangerouslySetInnerHTML={{
                                    __html: notification.text,
                                  }}
                                ></div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="pb-2 border-b text-xxs font-medium border-white/10 cursor-pointer"
                                onClick={(e: any) =>
                                  handlePin(e, notification.id)
                                }
                              >
                                <Tooltip
                                  anchorId={`record-pin-tooltip${index}`}
                                  content="Mark as Pinned"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                />
                                <div
                                  className="group-hover:opacity-100 group-hover:visible group-hover:inline-flex opacity-0 invisible hidden overflow-hidden items-center"
                                  id={`record-pin-tooltip${index}`}
                                >
                                  <KTSVG
                                    className="inline-flex fill-sky-blue-500 text-center mr-1"
                                    svgClassName="w-1.5 h-2"
                                    path="standard_icons/pin-icon.svg"
                                  />
                                  <span className="pr-1 text-sky-blue-500">
                                    Pin To Top
                                  </span>
                                </div>
                                <span className="text-gray-500">
                                  {moment(notification.created_at).format(
                                    "DD MMM"
                                  )}
                                </span>
                                <div
                                  className="text-white pt-1"
                                  dangerouslySetInnerHTML={{
                                    __html: notification.text,
                                  }}
                                ></div>
                              </div>
                            </>
                          )}
                        </li>
                      )
                    )
                  ) : layout?.tabValue === "activity" &&
                    layout?.activities?.length > 0 ? (
                    layout?.activities?.map((activity: any, aIndex: number) => (
                      <li className="pt-2 px-5 transition" key={aIndex}>
                        {activity.is_pinned ? (
                          <div className="pb-2 border-b text-xxs font-medium border-white/10">
                            <div className="text-sky-blue-500 mb-1">
                              <KTSVG
                                className="inline-flex fill-sky-blue-500 text-center mr-1"
                                svgClassName="w-1.5 h-2"
                                path="standard_icons/pin-icon.svg"
                              />
                              {moment(activity.created_at).format("DD MMM")}
                            </div>
                            <div
                              className="text-white"
                              dangerouslySetInnerHTML={{
                                __html: activity.text,
                              }}
                            ></div>
                          </div>
                        ) : activity.is_new ? (
                          <div className="pb-2 border-b text-xxs font-medium border-gray-400">
                            <div className="flex items-center mr-1 text-green-600">
                              <span className="w-2 h-2 rounded-full bg-green-600 mr-1.5"></span>
                              <span className="px-1">New</span>
                              {moment(activity.created_at).format("DD MMM")}
                            </div>
                            <div
                              className="text-white"
                              dangerouslySetInnerHTML={{
                                __html: activity.text,
                              }}
                            ></div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </li>
                    ))
                  ) : (
                    <p className="text-white text-center text-xs py-4">
                      No records found at the moment.
                    </p>
                  )}
                </ul>
              </div>
            )}
          </div>
        ) : (
          " "
        )}
      </div>
    </>
  );
};

export default AsideDefault;
