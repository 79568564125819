import _, { find } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import CollapseSVG from "../../../assets/media/icons/other_icons/Collapse.svg";
import AngleLeft from "../../../assets/media/icons/standard_icons/angle_left.svg";
import AngleRight from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { addCommaInNumber } from "../../../helpers/Functions";
import ReportsCountBoxes from "./ReportsCountBoxes";

const TabBody = ({ children, bodyId, isFirst, data, reportsLoading }: any) => {
  const coreLayout = useContext(LayoutContext);
  const [combined_array, setCombine_Array] = useState<any>();
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  // GETTING ARRAYS
  const event_wise_average_tickets_per_order =
    data?.revenue?.event_wise_average_tickets_per_order;
  const event_wise_order = data?.revenue?.event_wise_order;
  const event_wise_revenue = data?.revenue?.event_wise_revenue;
  const event_wise_ticket_sold = data?.revenue?.event_wise_ticket_sold;
  const event_wise_average_order_value =
    data?.revenue?.event_wise_average_order_value;
  const event_names = data?.revenue?.event_name;

  const filter_scroll: any = useRef();
  const scroll_filter = (scrollOffset: any) => {
    filter_scroll.current.scrollLeft += scrollOffset;
  };

  // SCROLLABLE BUTTON ON FILTER
  useEffect(() => {
    function hideArrowScroll() {
      const left_sc: any = document.getElementById("scroll_left");
      const right_sc: any = document.getElementById("scroll_right");
      const filter_scroll: any = document.getElementById("filter_scroll");
      const maxScrollLeft: any =
        filter_scroll?.scrollWidth - filter_scroll?.clientWidth;

      if (maxScrollLeft === 0) {
        // IF NO SCROLLABLE CONTENT
        left_sc.style.display = "none";
        right_sc.style.display = "none";
      } else {
        if (filter_scroll?.scrollLeft === 0) {
          left_sc.style.display = "none";
        } else {
          left_sc.style.display = "flex";
        }
        if (filter_scroll.scrollLeft >= maxScrollLeft) {
          right_sc.style.display = "none";
        } else {
          right_sc.style.display = "flex";
        }
      }
    }

    const filter_scroll = document.getElementById("filter_scroll");
    filter_scroll?.addEventListener("scroll", hideArrowScroll);

    // INITIAL CHECK TO SET BUTTON VISIBILITY ON MOUNT
    hideArrowScroll();

    return () => {
      filter_scroll?.removeEventListener("scroll", hideArrowScroll);
    };
  }, [coreLayout?.htmlFontSize, data]);

  useEffect(() => {
    let myObj: any = {
      // CUSTOM OBJECT
      event_id: "989",
      orderValue: data?.revenue?.average_order_value,
      avgOrderValue: data?.revenue?.average_tickets_per_order,
      totalSales: data?.revenue?.total_orders,
      revenue: data?.revenue?.total_revenue,
      ticketSold: data?.revenue?.total_tickets_sold,
    };

    // ARRAY TO COMBINE THE SELECTED EVENTS
    const combined_array = event_wise_average_order_value?.map(
      (item: any, index: any) => {
        const event_id = Object.keys(item)[0];
        const event_name = find(event_names, {
          event_id: event_id,
        })?.event_name;
        const event_wise_avg_order_value = Object.values(item)?.[0];
        const event_wise_avg_tickets_per_order = Object.values(
          event_wise_average_tickets_per_order[index]
        )[0];
        const event_wise_orders = Object.values(event_wise_order[index])[0];
        const event_wise_rev = Object.values(event_wise_revenue[index])[0];
        const event_wise_tickets_sold = Object.values(
          event_wise_ticket_sold[index]
        )[0];
        return {
          event_id,
          event_name,
          orderValue: event_wise_avg_order_value,
          avgOrderValue: event_wise_avg_tickets_per_order,
          totalSales: event_wise_orders,
          revenue: event_wise_rev,
          ticketSold: event_wise_tickets_sold,
        };
      }
    );

    // PUSH CUSTOM OBJECT IF EVETS ARE MORE THAN ONE
    if (combined_array?.length >= 2) {
      combined_array.unshift(myObj);
    }
    combined_array && setCombine_Array(combined_array);
  }, [
    data,
    event_wise_average_order_value,
    event_wise_average_tickets_per_order,
    event_wise_order,
    event_wise_revenue,
    event_wise_ticket_sold,
    event_names,
  ]);

  if (isFirst && isFirst) {
    return (
      <>
        <div
          className="tab_vontent hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
          id={bodyId}
          role="tabpanel"
          aria-labelledby={`tabs-${bodyId}`}
          data-te-tab-active
        >
          {/*first layer*/}
          <div className="relative px-5 pt-3">
            <span className="text-sm15 font-semibold">Overview</span>
          </div>
          <div className="relative px-5">
            <div
              id={`scroll_right`}
              className="arrow_slide hidden absolute top-[calc(50%-0.5rem)] -translate-y-1/2 w-6 h-6 flex items-center justify-center right-0 bg-gray-100 rounded-l group hover:bg-indigo-500 cursor-pointer"
              onClick={() => scroll_filter(50)}
            >
              <IKTSVG
                path={AngleRight}
                svgClassName="w-1.5 h-[.5625rem]"
                className=" group-hover:fill-white"
              />
            </div>
            <div
              id={`scroll_left`}
              className="hidden arrow_slide absolute top-[calc(50%-0.5rem)] -translate-y-1/2 w-6 h-6 flex items-center justify-center left-0 bg-gray-100 rounded-r group hover:bg-indigo-500 cursor-pointer"
              onClick={() => scroll_filter(-50)}
            >
              <IKTSVG
                path={AngleLeft}
                svgClassName="w-1.5 h-[.5625rem]"
                className=" group-hover:fill-white"
              />
            </div>

            <div className="divide-gray-200 divide-y-2 report_box">
              {_.size(combined_array) > 0 ? (
                combined_array &&
                combined_array?.map((item: any, index: any) => {
                  return (
                    <>
                      <div className="flex flex-wrap pt-3 -mx-2.5 456 with-acco">
                        <div
                          className="title w-full px-2.5 mb-4 cursor-pointer"
                          id={`EventHeading${index}`}
                          data-te-collapse-init
                          data-te-toggle="collapse"
                          data-te-target={`#EventCollapseBody${index}`}
                          aria-expanded="false"
                          aria-controls={`EventCollapseBody${index}`}
                        >
                          <div className="flex md:flex-nowrap flex-wrap w-full items-center border rounded pl-4 ">
                            <span className="text-sm14 font-medium">
                              {item?.event_name}
                            </span>
                            <button
                              className="group relative flex items-center py-4 text-base text-gray-800 text-left border-l !transition focus:outline-none group ml-auto w-[2.5625rem] justify-center"
                              type="button"
                              data-te-collapse-init
                              data-te-collapse-collapsed
                              data-te-toggle="collapse"
                              data-te-target={`#EventCollapseBody${index}`}
                              aria-expanded="true"
                              aria-controls={`EventCollapseBody${index}`}
                            >
                              <IKTSVG
                                className="flex items-center justify-center group-[.collapsed]:rotate-180 fill-violet-500 group-hover:fill-indigo-500 transition 
                        group-[[data-te-collapse-collapsed]]:rotate-180 rotate-180}"
                                path={CollapseSVG}
                              />
                            </button>
                          </div>
                        </div>
                        <div
                          className="acc_box flex flex-wrap w-full hidden"
                          id={`EventCollapseBody${index}`}
                          // className="accordion-collapse collapse show accordion-cont"
                          aria-labelledby={`EventCollapseBody${index}`}
                          data-te-collapse-item
                        >
                          <ReportsCountBoxes
                            name="Total sales"
                            isCheckbox={false}
                            countValue={
                              item?.totalSales
                                ? addCommaInNumber(item?.totalSales)
                                : 0
                            }
                            extraProp={false}
                            ticketCount={data?.revenue?.last_order_sold_amount}
                            reportsLoading={reportsLoading}
                          />

                          <ReportsCountBoxes
                            name={
                              isGodAdmin
                                ? `Gross transaction value`
                                : `Total revenue`
                            }
                            isCheckbox={false}
                            countValue={item?.revenue}
                            extraProp={true}
                            ticketCount={`
                           
                            ${
                              data?.revenue?.last_order_revenue
                                ? data?.revenue?.last_order_revenue
                                : 0
                            }`}
                            reportsLoading={reportsLoading}
                          />
                          <ReportsCountBoxes
                            name={
                              isGodAdmin
                                ? `Total seller payouts`
                                : `Total payouts`
                            }
                            isCheckbox={false}
                            countValue={data?.revenue?.total_payouts_orders}
                            extraProp={true}
                            // ticketCount={`${
                            //   coreLayout.currencySymbol ? coreLayout.currencySymbol : ""
                            // }${
                            //   data?.revenue?.last_order_revenue
                            //     ? data?.revenue?.last_order_revenue
                            //     : 0
                            // }`}
                            reportsLoading={reportsLoading}
                          />

                          <ReportsCountBoxes
                            name="Average order value"
                            isCheckbox={false}
                            countValue={item?.orderValue}
                            extraProp={true}
                            reportsLoading={reportsLoading}
                          />

                          <ReportsCountBoxes
                            name="Total tickets sold"
                            isCheckbox={false}
                            countValue={item?.ticketSold}
                            extraProp={false}
                            ticketCount={data?.revenue?.last_order_sold_amount}
                            reportsLoading={reportsLoading}
                          />

                          <ReportsCountBoxes
                            name="Average tickets per order"
                            isCheckbox={false}
                            countValue={item?.avgOrderValue}
                            extraProp={false}
                            reportsLoading={reportsLoading}
                          />

                          {/* <ReportsCountBoxes
                            name="Report charts"
                            isCheckbox={false}
                            countValue="0"
                            extraProp={false}
                          /> */}
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div
                  ref={filter_scroll}
                  id={`filter_scroll`}
                  className="flex flex-nowrap pt-3 -mx-2.5 overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
                >
                  <ReportsCountBoxes
                    name="Total sales"
                    isCheckbox={false}
                    countValue={
                      data?.revenue?.total_orders
                        ? addCommaInNumber(data?.revenue?.total_orders)
                        : 0
                    }
                    extraProp={false}
                    ticketCount={data?.revenue?.last_order_sold_amount}
                    reportsLoading={reportsLoading}
                  />

                  <ReportsCountBoxes
                    name={
                      isGodAdmin ? `Gross transaction value` : `Total revenue`
                    }
                    isCheckbox={false}
                    countValue={data?.revenue?.total_revenue}
                    extraProp={true}
                    ticketCount={`${
                      data?.revenue?.last_order_revenue
                        ? data?.revenue?.last_order_revenue
                        : 0
                    }`}
                    reportsLoading={reportsLoading}
                  />

                  {!isGodAdmin && (
                    <ReportsCountBoxes
                      name={`Total payouts`}
                      isCheckbox={false}
                      countValue={data?.revenue?.total_payouts_orders}
                      extraProp={true}
                      // ticketCount={`${
                      //   coreLayout.currencySymbol ? coreLayout.currencySymbol : ""
                      // }${
                      //   data?.revenue?.last_order_revenue
                      //     ? data?.revenue?.last_order_revenue
                      //     : 0
                      // }`}
                      reportsLoading={reportsLoading}
                    />
                  )}

                  {isGodAdmin && (
                    <>
                      <ReportsCountBoxes
                        name="Tixstock revenue"
                        isCheckbox={false}
                        countValue={data?.revenue?.tixstock_revenue}
                        extraProp={true}
                        reportsLoading={reportsLoading}
                      />
                      <ReportsCountBoxes
                        name="Total seller payouts"
                        isCheckbox={false}
                        countValue={data?.revenue?.settlement_net_payout}
                        extraProp={true}
                        reportsLoading={reportsLoading}
                      />
                    </>
                  )}

                  <ReportsCountBoxes
                    name="Average order value"
                    isCheckbox={false}
                    countValue={data?.revenue?.average_order_value}
                    extraProp={true}
                    reportsLoading={reportsLoading}
                  />

                  <ReportsCountBoxes
                    name="Total tickets sold"
                    isCheckbox={false}
                    countValue={data?.revenue?.total_tickets_sold}
                    extraProp={false}
                    ticketCount={data?.revenue?.last_order_sold_amount}
                    reportsLoading={reportsLoading}
                  />

                  <ReportsCountBoxes
                    name="Average tickets per order"
                    isCheckbox={false}
                    countValue={data?.revenue?.average_tickets_per_order}
                    extraProp={false}
                    reportsLoading={reportsLoading}
                  />

                  {/* <ReportsCountBoxes
                    name="Report charts"
                    isCheckbox={false}
                    countValue="0"
                    extraProp={false}
                  /> */}
                </div>
              )}
            </div>
          </div>

          {children}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className=" tab_vontent hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
          id={bodyId}
          role="tabpanel"
          aria-labelledby={`tabs-${bodyId}`}
        >
          {/*first layer*/}
          <div className="px-5 pt-3 border-b">
            <div className="divide-gray-200 divide-y-2">
              <div className="flex flex-wrap xl:flex-nowrap pt-3 -mx-2.5"></div>
            </div>
          </div>
          {children}
        </div>
      </>
    );
  }
};

export default TabBody;
