// FileUpload.js

import { IKTSVG } from "components/ui/IKTSVG";
import { base64TOURL } from "helpers/AssetHelpers";
import { showAlert } from "helpers/ShowAlert";
import { size } from "lodash";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { useDropzone } from "react-dropzone";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { default as eyeSVG } from "../../../../assets/media/icons/other_icons/Eye.svg";
import Upload from "../../../../assets/media/icons/other_icons/Upload.svg";
import { default as CrossSVG } from "../../../../assets/media/icons/standard_icons/cross.svg";

const FileUpload = ({
  files,
  onFilesUploaded,
  deleteFiles,
  handleViewPDF,
}: any) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    maxSize: 5244899, // 5MB in bytes
    onDrop: (acceptedFiles, rejectedFiles) => {
      // Handling rejected files (e.g. file too large, invalid type)
      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) =>
              error.code === "file-too-large"
                ? "Please upload less than 5MB"
                : "Invalid File Type"
            )
          )
        )
      );

      if (uniqueCodes.length > 0) {
        showAlert(uniqueCodes.join(", <br/>"), true);
      }

      // Add preview and id to each accepted file
      const filesWithPreview = acceptedFiles?.map((file) => {
        const reader: any = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          // @ts-ignore
          file.base64 = reader.result.substr(reader.result.indexOf(",") + 1);
        };
        return file;
      });

      // Pass the accepted files back to the parent
      onFilesUploaded(filesWithPreview);
    },
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
  });

  return (
    <div className="inner-part px-3 py-3 flex flex-wrap w-full">
      <div
        {...getRootProps({ className: "dropzone" })}
        className={`flex mb-[1rem] flex-col items-center justify-center text-center w-full min-h-[5rem] border border-dashed border-violet-500/10 rounded bg-violet-300/50 text-xs`}
      >
        <input {...getInputProps()} name="banner_image" />
        <div className="mb-2.5">Drag files here or click to upload</div>
        <button
          type="button"
          onClick={open}
          className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 px-2.5 py-1"
        >
          <IKTSVG
            className="fill-white mr-2"
            path={Upload}
            svgClassName="w-[.875rem] h-[.75rem]"
          />
          Upload
        </button>
      </div>
      {files && size(files) === 0 ? (
        <div className="text-sm13 font-medium text-center p-2 bg-white rounded w-full undefined">
          No data available
        </div>
      ) : (
        <TransitionGroup component="div" className="flex-1 w-full">
          {files?.map((file: any, index: number) => {
            console.log(file);
            return (
              <CSSTransition key={index} classNames="row" timeout={500}>
                <div className="list_wrap flex flex-wrap gap-2.5 anim-fade w-full mb-2">
                  <div
                    className={`single-invoice border rounded flex flex-wrap justify-between px-2.5 py-1.5 text-sm12 bg-white w-full`}
                  >
                    <span className="flex-1 flex leading-4">{file?.name}</span>
                    <button
                      className="ml-2"
                      type="button"
                      data-tooltip-id={`pdf-view-account-${index}`}
                      onClick={() => {
                        window.open(
                          file?.base64
                            ? base64TOURL(file?.base64, file?.type)
                            : file?.path
                            ? file?.path
                            : file?.url,
                          "_blank"
                        );
                      }}
                    >
                      <TooltipPortal
                        id={`pdf-view-account-${index}`}
                        content={"View"}
                        className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                      />
                      <IKTSVG
                        className="fill-violet-500 hover:fill-indigo-500 transition"
                        path={eyeSVG}
                        svgClassName="w-[.9375rem] h-2.5"
                      />
                    </button>

                    <button
                      className="ml-2"
                      type="button"
                      data-tooltip-id="kyc-delete-file"
                      onClick={() => deleteFiles(file)}
                    >
                      <TooltipPortal
                        id={`kyc-delete-file`}
                        content={"Delete"}
                        className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                      />
                      <IKTSVG
                        className="fill-violet-500 hover:fill-indigo-500 transition"
                        path={CrossSVG}
                        svgClassName="w-[.5625rem] h-[.5625rem]"
                      />
                    </button>
                  </div>
                </div>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      )}
    </div>
  );
};

export default FileUpload;
