import { size } from "lodash";
import moment from "moment";
import React, { memo, useContext } from "react";
import { Tooltip } from "react-tooltip";
import filter from "../../../assets/media/icons/other_icons/filter.svg";
import { default as refreshSVG } from "../../../assets/media/icons/other_icons/refresh.svg";
import crossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { addCommaInNumber, ticketTypeRename } from "../../../helpers/Functions";
import { SalesContext } from "../core/SalesProvider";

const SalesFilterChip = memo(
  ({
    shimmerLoader,
    paginateData,
    paginateData1,
    query,
    debouncedSearch,
    handleQuery,
    setInputChange,
    ToggleElemOpen,
  }: any) => {
    const layout = useContext(SalesContext);

    // RESET ALL FILTERS
    const handleRefresh = () => {
      handleQuery("");
      setInputChange("");
      layout.setSalesFilterHandle((currentVal: any) => {
        return {
          team_member: [],
          event_id: [],
          team_id: [],
          category_ids: null,
          page: 1,
          per_page: 20,
        };
      });
    };

    let isTicketTypeExist =
      layout.salesFilterHandle?.ticket_type &&
      size(layout.salesFilterHandle?.ticket_type) > 0 &&
      Object.values(layout.salesFilterHandle?.ticket_type)?.some(
        (item: any) => item
      );

    return (
      <div
        className="flex flex-wrap customStyle relative bg-white border-t max-sm:justify-between"
        id={`salesFilter`}
      >
        <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
          {shimmerLoader ? (
            <div className="shimmer-effect inline-block rounded">
              <span className="opacity-0">
                {paginateData?.total > 0
                  ? `${addCommaInNumber(paginateData?.total)} results`
                  : "0 results"}
              </span>
            </div>
          ) : (
            <>
              {paginateData1 > 0
                ? `${addCommaInNumber(paginateData1)} results`
                : "0 result"}
            </>
          )}
        </div>
        <div className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start">
          {/* SEARCH DATA */}

          {shimmerLoader ? (
            <div className="flex gap-5">
              <div
                className={`flex !rounded overflow-hidden ${
                  shimmerLoader && "shimmer-effect"
                }`}
                style={{ height: "1.625rem", width: "9.375rem" }}
              >
                &nbsp;
              </div>
            </div>
          ) : (
            <>
              {((layout.salesFilterHandle?.team_member &&
                layout.salesFilterHandle?.team_member.length > 0) ||
                (layout.salesFilterHandle?.buyer_team_id &&
                  layout.salesFilterHandle?.buyer_team_id.length > 0) ||
                (query !== undefined && debouncedSearch) ||
                (layout.salesFilterHandle?.event_id &&
                  layout.salesFilterHandle?.event_id?.length > 0) ||
                layout.salesFilterHandle?.time_to_event ||
                (layout.salesFilterHandle?.deliveryDateRange &&
                  layout.salesFilterHandle?.deliveryDateRange?.startDate !==
                    undefined &&
                  layout.salesFilterHandle?.deliveryDateRange?.endDate !==
                    undefined) ||
                (layout.salesFilterHandle?.event_date &&
                  layout.salesFilterHandle?.event_date?.startDate !==
                    undefined &&
                  layout.salesFilterHandle?.event_date?.endDate !==
                    undefined) ||
                (layout.salesFilterHandle?.orderDateRange &&
                  layout.salesFilterHandle?.orderDateRange?.startDate !==
                    undefined &&
                  layout.salesFilterHandle?.orderDateRange?.endDate !==
                    undefined) ||
                (layout.salesFilterHandle?.order_exchanges &&
                  layout.salesFilterHandle?.order_exchanges.length > 0) ||
                layout?.salesFilterHandle?.team_id?.length > 0 ||
                layout?.salesFilterHandle?.internal_order_statuses?.length >
                  0 ||
                layout?.salesFilterHandle?.category_ids?.length > 0 ||
                layout?.salesFilterHandle?.performer_ids?.length > 0 ||
                layout?.salesFilterHandle?.store_id ||
                layout.salesFilterHandle?.ticket_in_hand ||
                isTicketTypeExist) && (
                <button
                  type="button"
                  className={`group border text-center bg-white rounded w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition ${
                    shimmerLoader && "shimmer-effect"
                  }`}
                  onClick={handleRefresh}
                  title="Reset"
                  id="reset"
                >
                  <Tooltip
                    anchorId={`reset`}
                    content={`Reset`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                  />
                  <IKTSVG
                    path={refreshSVG}
                    className="fill-violet-500 group-hover:fill-white transition"
                    svgClassName="mx-[.313rem] w-3 h-3"
                  />
                </button>
              )}
              {/* TEAM  */}
              {layout.salesFilterHandle?.team_id &&
                layout.salesFilterHandle?.team_id?.length > 0 &&
                layout.salesFilterHandle?.team_id?.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                          {user?.name}
                          <button
                            type="button"
                            className="ml-2 hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setSalesFilterHandle((currentVal: any) => {
                                let team_id = currentVal?.team_id?.filter(
                                  (user: any, indexNumber: number) =>
                                    index !== indexNumber
                                );
                                return {
                                  ...currentVal,
                                  team_id: team_id,
                                  page: 1,
                                  per_page: 20,
                                };
                              });
                            }}
                          >
                            <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {/* TEAM MEMBER */}
              {layout.salesFilterHandle?.team_member &&
                layout.salesFilterHandle?.team_member?.length > 0 &&
                layout.salesFilterHandle?.team_member.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={`flex py-1 px-2.5 text-xs border rounded-full my-0.5 ${
                            shimmerLoader && "shimmer-effect"
                          } `}
                        >
                          <span className={`${shimmerLoader && "opacity-0"}`}>
                            {user?.name}
                          </span>
                          <button
                            type="button"
                            className="ml-2 hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setSalesFilterHandle((currentVal: any) => {
                                let team_member =
                                  currentVal?.team_member?.filter(
                                    (user: any, indexNumber: number) =>
                                      index !== indexNumber
                                  );
                                return {
                                  ...currentVal,
                                  team_member: team_member,
                                  page: 1,
                                  per_page: 20,
                                };
                              });
                            }}
                          >
                            <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {/* DEBOUNCE QUERY */}
              {debouncedSearch && (
                <div
                  className={`${
                    query
                      ? "flex py-1 px-2.5 text-xs border rounded-full my-0.5"
                      : "hidden"
                  } `}
                >
                  {query}
                  <button
                    type="button"
                    className="ml-2  hover:fill-indigo-500"
                    onClick={() => {
                      handleQuery("");
                      setInputChange("");
                    }}
                  >
                    <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                  </button>
                </div>
              )}

              {/* ALGOLIA SELECTED OPTIONS */}
              {layout.salesFilterHandle?.event_id &&
                layout.salesFilterHandle?.event_id?.length > 0 &&
                layout.salesFilterHandle?.event_id.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                          {user?.name}
                          <button
                            type="button"
                            className="ml-2  hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setSalesFilterHandle((currentVal: any) => {
                                let event_id = currentVal?.event_id?.filter(
                                  (user: any, indexNumber: number) =>
                                    index !== indexNumber
                                );
                                return {
                                  ...currentVal,
                                  event_id: event_id,
                                  page: 1,
                                  per_page: 20,
                                };
                              });
                            }}
                          >
                            <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {/* TICKET TYPE */}
              {layout.salesFilterHandle?.ticket_type &&
                Object.entries(layout.salesFilterHandle?.ticket_type).map(
                  ([key, value]: any) => {
                    if (value) {
                      return (
                        <div className="flex py-1 px-2.5 text-xs border rounded-full capitalize">
                          {/* {value?.name} */}
                          {ticketTypeRename(key)}

                          <button
                            type="button"
                            className="ml-2  hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setSalesFilterHandle((currentVal: any) => {
                                return {
                                  ...currentVal,
                                  ticket_type: {
                                    ...currentVal?.ticket_type,
                                    [key]: false,
                                  },
                                  ticket_type_dropdown:
                                    currentVal?.ticket_type_dropdown?.filter(
                                      (item: any) => item?.id !== key
                                    ),
                                  page: 1,
                                  per_page: 20,
                                };
                              });
                            }}
                          >
                            <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                          </button>
                        </div>
                      );
                    }
                  }
                )}

              {/* TICKET IN HAND */}
              {layout.salesFilterHandle?.ticket_in_hand && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full">
                  Tickets in hand
                  <button
                    type="button"
                    className="ml-2  hover:fill-indigo-500"
                    onClick={(e: any) => {
                      layout.setSalesFilterHandle((currentVal: any) => {
                        return {
                          ...currentVal,
                          ticket_in_hand: false,
                          page: 1,
                          per_page: 20,
                        };
                      });
                    }}
                  >
                    <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                  </button>
                </div>
              )}

              {/* MARKETPLACE */}
              {/* {layout.salesFilterHandle?.order_exchanges &&
            Object.keys(layout.salesFilterHandle?.order_exchanges).length >
              0 && (
              <div className="flex py-1 px-2.5 text-xs border rounded-full">
                {layout.salesFilterHandle?.order_exchanges?.name}
                <button
                  type="button"
                  className="ml-2"
                  onClick={(e: any) => {
                    layout.setSalesFilterHandle((currentVal: any) => {
                      return {
                        ...currentVal,
                        order_exchanges: "",
                      };
                    });
                  }}
                >
                  <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                </button>
              </div>
            )} */}

              {/* ORDER DATE */}
              {layout.salesFilterHandle?.orderDateRange &&
                layout.salesFilterHandle?.orderDateRange?.startDate !==
                  undefined &&
                layout.salesFilterHandle?.orderDateRange?.endDate !==
                  undefined && (
                  <div className="flex py-1 px-2.5 text-xs border rounded-full">
                    {moment(
                      layout.salesFilterHandle?.orderDateRange?.startDate
                    ).format("DD/MM/YYYY")}
                    <span className="px-2">-</span>
                    {moment(
                      layout.salesFilterHandle?.orderDateRange?.endDate
                    ).format("DD/MM/YYYY")}
                    <button
                      type="button"
                      className="ml-2  hover:fill-indigo-500"
                      onClick={(e: any) => {
                        layout.setSalesFilterHandle((currentVal: any) => {
                          return {
                            ...currentVal,
                            orderDateRange: "",
                            page: 1,
                            per_page: 20,
                          };
                        });
                      }}
                    >
                      <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                    </button>
                  </div>
                )}

              {/* EVENT DATE */}
              {layout.salesFilterHandle?.event_date &&
                layout.salesFilterHandle?.event_date?.startDate !== undefined &&
                layout.salesFilterHandle?.event_date?.endDate !== undefined && (
                  <div className="flex py-1 px-2.5 text-xs border rounded-full">
                    {moment(
                      layout.salesFilterHandle?.event_date?.startDate
                    ).format("DD/MM/YYYY")}
                    <span className="px-2">-</span>
                    {moment(
                      layout.salesFilterHandle?.event_date?.endDate
                    ).format("DD/MM/YYYY")}
                    <button
                      type="button"
                      className="ml-2  hover:fill-indigo-500"
                      onClick={(e: any) => {
                        layout.setSalesFilterHandle((currentVal: any) => {
                          return {
                            ...currentVal,
                            event_date: "",
                            page: 1,
                            per_page: 20,
                          };
                        });
                      }}
                    >
                      <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                    </button>
                  </div>
                )}

              {/* DELIVERY DATE */}
              {layout.salesFilterHandle?.deliveryDateRange &&
                layout.salesFilterHandle?.deliveryDateRange?.startDate !==
                  undefined &&
                layout.salesFilterHandle?.deliveryDateRange?.endDate !==
                  undefined && (
                  <div className="flex py-1 px-2.5 text-xs border rounded-full">
                    {moment(
                      layout.salesFilterHandle?.deliveryDateRange?.startDate
                    ).format("DD/MM/YYYY")}
                    <span className="px-2">-</span>
                    {moment(
                      layout.salesFilterHandle?.deliveryDateRange?.endDate
                    ).format("DD/MM/YYYY")}
                    <button
                      type="button"
                      className="ml-2  hover:fill-indigo-500"
                      onClick={(e: any) => {
                        layout.setSalesFilterHandle((currentVal: any) => {
                          return {
                            ...currentVal,
                            deliveryDateRange: "",
                            page: 1,
                            per_page: 20,
                          };
                        });
                      }}
                    >
                      <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                    </button>
                  </div>
                )}

              {/* TIME TO EVENT */}
              {layout.salesFilterHandle?.time_to_event &&
                Object.keys(layout.salesFilterHandle?.time_to_event).length >
                  0 && (
                  <div className="flex py-1 px-2.5 text-xs border rounded-full">
                    {layout.salesFilterHandle?.time_to_event?.label}
                    <button
                      type="button"
                      className="ml-2  hover:fill-indigo-500"
                      onClick={(e: any) => {
                        layout.setSalesFilterHandle((currentVal: any) => {
                          return {
                            ...currentVal,
                            time_to_event: "",
                            page: 1,
                            per_page: 20,
                          };
                        });
                      }}
                    >
                      <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                    </button>
                  </div>
                )}

              {/* ORDER EXCHANGE */}
              {layout.salesFilterHandle?.order_exchanges &&
                layout.salesFilterHandle?.order_exchanges?.length > 0 &&
                layout.salesFilterHandle?.order_exchanges.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        {user?.name !== "" && (
                          <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                            {user?.name}
                            <button
                              type="button"
                              className="ml-2  hover:fill-indigo-500"
                              onClick={(e: any) => {
                                layout.setSalesFilterHandle(
                                  (currentVal: any) => {
                                    let order_exchanges =
                                      currentVal?.order_exchanges?.filter(
                                        (user: any, indexNumber: number) =>
                                          index !== indexNumber
                                      );
                                    return {
                                      ...currentVal,
                                      order_exchanges: order_exchanges,
                                      page: 1,
                                      per_page: 20,
                                    };
                                  }
                                );
                              }}
                            >
                              <IKTSVG
                                path={crossSVG}
                                svgClassName="h-2 w-2 hover:fill-indigo-500"
                              />
                            </button>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }
                )}

              {/* ORDER STATUS */}
              {layout.salesFilterHandle?.order_statuses &&
                layout.salesFilterHandle?.order_statuses?.length > 0 &&
                layout.salesFilterHandle?.order_statuses.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full">
                          {user?.description}
                          <button
                            type="button"
                            className="ml-2  hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setSalesFilterHandle((currentVal: any) => {
                                let order_statuses =
                                  currentVal?.order_statuses?.filter(
                                    (user: any, indexNumber: number) =>
                                      index !== indexNumber
                                  );
                                return {
                                  ...currentVal,
                                  order_statuses: order_statuses,
                                  page: 1,
                                  per_page: 20,
                                };
                              });
                            }}
                          >
                            <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {/* INTERNAL ORDER  */}
              {layout.salesFilterHandle?.internal_order_statuses &&
                layout.salesFilterHandle?.internal_order_statuses.map(
                  (obj: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full">
                          {obj?.name}
                          <button
                            type="button"
                            className="ml-2  hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setSalesFilterHandle((currentVal: any) => {
                                let internal_order_statuses =
                                  currentVal?.internal_order_statuses?.filter(
                                    (user: any, indexNumber: number) =>
                                      index !== indexNumber
                                  );
                                return {
                                  ...currentVal,
                                  internal_order_statuses:
                                    internal_order_statuses,
                                  page: 1,
                                  per_page: 20,
                                };
                              });
                            }}
                          >
                            <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {/* INCLUDE WEBSTORES - FILTER CHIP */}
              {layout.salesFilterHandle?.store_id && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full">
                  Include webstores
                  <button
                    type="button"
                    className="ml-2  hover:fill-indigo-500"
                    onClick={(e: any) => {
                      layout.setSalesFilterHandle((currentVal: any) => {
                        return {
                          ...currentVal,
                          store_id: false,
                          page: 1,
                          per_page: 20,
                        };
                      });
                    }}
                  >
                    <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                  </button>
                </div>
              )}

              {layout.salesFilterHandle?.category_ids &&
                layout.salesFilterHandle?.category_ids.map(
                  (obj: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full">
                          {obj?.name}
                          <button
                            type="button"
                            className="ml-2  hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setSalesFilterHandle((currentVal: any) => {
                                let category_ids =
                                  currentVal?.category_ids?.filter(
                                    (user: any, indexNumber: number) =>
                                      index !== indexNumber
                                  );
                                return {
                                  ...currentVal,
                                  category_ids: category_ids,
                                  page: 1,
                                  per_page: 20,
                                };
                              });
                            }}
                          >
                            <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {layout.salesFilterHandle?.performer_ids &&
                layout.salesFilterHandle?.performer_ids.map(
                  (obj: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full">
                          {obj?.name}
                          <button
                            type="button"
                            className="ml-2  hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setSalesFilterHandle((currentVal: any) => {
                                let performer_ids =
                                  currentVal?.performer_ids?.filter(
                                    (user: any, indexNumber: number) =>
                                      index !== indexNumber
                                  );
                                return {
                                  ...currentVal,
                                  performer_ids: performer_ids,
                                  page: 1,
                                  per_page: 20,
                                };
                              });
                            }}
                          >
                            <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {/* BUYER */}
              {layout.salesFilterHandle?.buyer_team_id &&
                layout.salesFilterHandle?.buyer_team_id.map(
                  (obj: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full">
                          {obj?.name}
                          <button
                            type="button"
                            className="ml-2  hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setSalesFilterHandle((currentVal: any) => {
                                let buyer_team_id =
                                  currentVal?.buyer_team_id?.filter(
                                    (user: any, indexNumber: number) =>
                                      index !== indexNumber
                                  );
                                return {
                                  ...currentVal,
                                  buyer_team_id: buyer_team_id,
                                  page: 1,
                                  per_page: 20,
                                };
                              });
                            }}
                          >
                            <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}
            </>
          )}
        </div>
        <div
          className="filterBtn max-md:flex max-md:items-center hidden p-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[9rem]"
          id="filterBtn"
          onClick={ToggleElemOpen}
        >
          <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
            <IKTSVG
              path={filter}
              className="fill-white mr-1.5"
              svgClassName="w-4 h-4"
            />
            Filter results
          </button>
        </div>
        {/* <Pagination paginateData={paginateData} /> */}
      </div>
    );
  }
);

export default SalesFilterChip;
