import React, { memo, useContext, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import Select, {
  NoticeProps,
  SingleValueProps,
  components,
} from "react-select";
// import CrossSVG from "../../assets/media/icons/standard_icons/cross.svg";
import CrossSVGdef from "../../assets/media/icons/standard_icons/def_cross.svg";
import CrossSVGhov from "../../assets/media/icons/standard_icons/hover_cross.svg";
import Warning1 from "../../assets/media/icons/standard_icons/warning1.svg";

import _ from "lodash";
import TooltipPortal from "../../pages/tx_trade/table/component/TooltipPortal";
import { LayoutContext } from "../core/LayoutProvider";
import { IKTSVG } from "../ui/IKTSVG";

const TableDropdown = memo(
  ({
    value,
    isOrderStatusDropdown,
    options,
    placeholder,
    noOptionsMessage,
    handleOnChange,
    name,
    getOptionLabel = "name",
    getOptionValue = "id",
    errorClassName = "",
    errorMessage = "",
    errorEventIndex = "",
    isDisabled,
    isClearable = true,
    isCapital = false,
    isValueCapital = false,
    errorindex,
    intializeField,
    dropDownWidth,
  }: any) => {
    const [optionNotExist, setOptionNotExist] = useState(false);
    const layout = useContext(LayoutContext);
    const ModuleTitle = layout?.title;
    const tableRef: any = React.useRef();

    useEffect(() => {
      if (intializeField === name) {
        if (tableRef?.current?.select) {
          tableRef.current.select.openMenu();
        }
      }
    }, [intializeField]);

    useEffect(() => {
      //need to check the value exist in the options
      if (value?.getOptionValue && Object.keys(options).length > 0) {
        let findValue = options.findIndex(
          (option: any) => option[getOptionLabel] === value[getOptionLabel]
        );
        findValue === -1 ? setOptionNotExist(true) : setOptionNotExist(false);
      } else {
        setOptionNotExist(false);
      }
    }, [getOptionLabel, options, value]);

    const customStyle = {
      option: (base: any, state: { isSelected: any; isFocused: any }) => {
        return {
          ...base,
          backgroundColor: state.isSelected
            ? ""
            : state.isFocused
            ? "#F5F5FE"
            : "white",
          "&:hover": {
            backgroundColor: "#F5F5FE",
            color: "#6F6CFF",
          },
          color: state.isSelected
            ? "#6F6CFF"
            : state.isFocused
            ? "#6F6CFF"
            : "#0D0019",
        };
      },
      menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 9999,
      }),
      control: (base: any, state: any) => {
        // console.log(optionNotExist, 'optionNotExist')
        return {
          ...base,
          boxShadow: "none",
          borderColor: optionNotExist && "#f9004b",
          "&:hover": {
            border: "1px solid",
            borderColor: optionNotExist ? "#f9004b" : "#6f6cff ",
            borderWidth: "1px",
          },
        };
      },
    };

    const MenuList = (props: any, notice: NoticeProps) => {
      return (
        <components.MenuList
          {...props}
          className="scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full "
        >
          {props.children}
        </components.MenuList>
      );
    };

    const ClearableOption = ({ children, ...props }: any) => {
      const handleClear = (e: any) => {
        e.stopPropagation();
        props.clearValue();
        props.selectProps.onMenuClose();
      };
      return (
        <components.Option
          {...props}
          className={
            props.isSelected && props?.selectProps?.isClearable && "actcross"
          }
        >
          {typeof children === "number" || isCapital
            ? children
            : _.capitalize(children?.toLowerCase())}
          {props.isSelected && props?.selectProps?.isClearable && (
            <button
              type="button"
              onClick={handleClear}
              className="group absolute right-[.75rem] top-1/2 -translate-y-1/2 w-[.875rem] h-[.875rem] rounded-full bg-white flex items-center justify-center hover:bg-indigo-500"
            >
              {/* <SVG src={CrossSVG} className={"w-2 h-2 group-hover:fill-white"} /> */}
              <SVG
                src={CrossSVGdef}
                className={"w-[14px] h-[14px] group-hover:hidden"}
              />
              <SVG
                src={CrossSVGhov}
                className={"w-[14px] h-[14px] group-hover:block hidden"}
              />
            </button>
          )}
        </components.Option>
      );
    };

    const SingleValue = ({ children, ...props }: SingleValueProps<any>) => {
      return (
        <components.SingleValue {...props}>
          {typeof children === "number" || isValueCapital
            ? children
            : typeof children === "string" &&
              _.capitalize(children?.toLowerCase())}
        </components.SingleValue>
      );
    };

    return (
      <>
        <div
          className={`relative dropdown-main-flex ${
            isOrderStatusDropdown ? "w-[inherit]" : ""
          } `}
          title={value?.[getOptionLabel]}
        >
          <Select
            ref={tableRef}
            options={options}
            isDisabled={isDisabled}
            value={
              value
                ? value
                : ModuleTitle === "Add Inventory" || ModuleTitle === "Publish"
                ? null
                : ModuleTitle === "Inventory" && name === "section"
                ? null
                : undefined
            }
            name={name}
            onChange={(selectedOption: any) => {
              let event = { target: { name: name, value: selectedOption } };
              handleOnChange(event);
            }}
            isClearable={isClearable ? isClearable : false}
            // menuIsOpen={true}
            tabSelectsValue={false}
            placeholder={placeholder}
            // placeholder={!hasValue ? placeholder : undefined}
            components={{ MenuList, Option: ClearableOption, SingleValue }}
            menuPortalTarget={document.body}
            noOptionsMessage={(inputValue: any) =>
              noOptionsMessage ? noOptionsMessage : "No options"
            }
            styles={customStyle}
            classNamePrefix="table_dropdown"
            className={`min-w-[6.875rem]  ${errorClassName} ${
              isOrderStatusDropdown ? "w-[inherit]" : "max-w-[6.875rem]"
            } dropdown_wrap ${dropDownWidth} `}
            menuPosition="fixed"
            menuPlacement="auto"
            getOptionLabel={(option) => option[getOptionLabel]}
            getOptionValue={(option) => option[getOptionValue]}
          />
          {value?.[getOptionLabel]
            ? ""
            : errorMessage &&
              errorEventIndex && (
                <>
                  <TooltipPortal
                    id={`error-${errorEventIndex}`}
                    content={errorMessage}
                    className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium text-xs z-10 max-w-[9rem] !text-rose-500"
                  />
                  <div
                    id={`error-${errorEventIndex}`}
                    data-tooltip-id={`error-${errorEventIndex}`}
                    className="absolute top-1/2 -translate-y-1/2 right-3 "
                  >
                    <IKTSVG
                      className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                      path={Warning1}
                    />
                  </div>
                </>
              )}
        </div>
      </>
    );
  }
);

export default TableDropdown;
