import { useEffect, useRef } from "react";
import WebFont from "webfontloader";
import crossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { getDynamicHTML } from "../core/_functions";

const AdditionalTemplate = ({
  template,
  setTemplateView = () => {},
  setTemplateHTML,
  templateInputes,
  handleOnClose = () => {},
}: any) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const onLoadHandler = () => {
    const iframeDocument: any = iframeRef.current?.contentWindow;
    if (iframeDocument) {
      WebFont.load({
        google: {
          families: [
            "Roboto",
            "Raleway",
            "Montserrat",
            "Lato",
            "Rubik",
            "PT Sans",
            "Sans Serif",
          ],
        },
        context: iframeDocument,
      });
    }
  };

  useEffect(() => {
    //   let updatedTemplateHTML = `<html><head>
    //   <style>
    //   .ql-syntax {
    //   background-color: #23241f;
    //   color: #f8f8f2;
    //   overflow: visible;
    //   }
    //   .ql-font-Roboto {
    //     font-family: "Roboto";
    //   }
    //   .ql-font-Raleway {
    //     font-family: "Raleway";
    //   }
    //   .ql-font-Montserrat {
    //     font-family: "Montserrat";
    //   }
    //   .ql-font-Lato {
    //     font-family: "Lato";
    //   }
    //   .ql-font-Rubik {
    //     font-family: "Rubik";
    //   }
    //   .ql-font-PT-Sans {
    //     font-family: "PT Sans";
    //   }
    //   .ql-font-sans-serif {
    //     font-family: "Sans Serif";
    //   }
    //   </style>
    // </head><body>${template?.html_content}</body></html>`;

    //   templateInputes &&
    //     Object.keys(templateInputes).forEach((key) => {
    //       const regex =
    //         key === "Dynamic content"
    //           ? new RegExp(`\\[DYNAMIC_CONTENT\\]`, "g")
    //           : new RegExp(`\\[${key}:DYNAMIC_CONTENT\\]`, "g");
    //       updatedTemplateHTML = updatedTemplateHTML.replace(
    //         regex,
    //         templateInputes[key]
    //           ? `${key === "Dynamic content" ? "" : key + " : "}${
    //               templateInputes[key]
    //             }`
    //           : `[${key === "Dynamic content" ? "" : key + " : "}DYNAMIC_CONTENT]`
    //       );
    //     });
    // setTemplateHTML(updatedTemplateHTML);

    // if (iframeRef.current) {
    //   const doc = iframeRef.current.contentDocument;
    //   if (doc) {
    //     doc.open();
    //     doc.write(getDynamicHTML(template, templateInputes));
    //     doc.close();
    //   }
    // }

    // if (iframeRef?.current) {
    //   const iframeDoc =
    //     iframeRef?.current?.contentDocument ||
    //     iframeRef?.current?.contentWindow?.document;

    //   if (iframeDoc) {
    //     iframeDoc.body.innerHTML =
    //       getDynamicHTML(template, templateInputes) + " ";
    //   }
    // }

    const iframe = iframeRef.current;

    const onLoad = () => {
      const iframeDoc =
        iframe?.contentDocument || iframe?.contentWindow?.document;

      if (iframeDoc) {
        iframeDoc.body.innerHTML =
          getDynamicHTML(template, templateInputes) + " ";
      }
    };

    if (iframe) {
      iframe.addEventListener("load", onLoad);
      onLoad();
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", onLoad);
      }
    };
  }, [template, templateInputes]);

  return (
    <div className=" absolute bg-white transition h-full lg2:border-r w-full lg2:h-full lg2:w-1/2 flex items-center !justify-start flex-col lg2:scrollbar-thin lg2:scrollbar-thumb-violet-800 lg2:scrollbar-thumb-rounded-full z-100 border">
      <div className="offcanvas-header flex items-center justify-between border-b w-[100%] sticky-top bg-white">
        <h5
          className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5 capitalize"
          id="offcanvasRightLabel"
        >
          {template?.title}
        </h5>
        <button
          type="button"
          className="flex items-center justify-center w-9 border-l box-content h-full group"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            setTemplateView(false);
            handleOnClose("click");
          }}
        >
          <IKTSVG
            className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
            path={crossSVG}
            svgClassName="h-[.625rem] w-[.625rem]"
          />
        </button>
      </div>
      {/* <div id="divToPrint" className="p-5 font-medium text-sm13">
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: templateHTML,
          }}
        ></div>
      </div> */}

      <iframe
        ref={iframeRef}
        className="w-full h-full pb-10"
        title="template"
        onLoad={onLoadHandler}
      ></iframe>
    </div>
  );
};

export default AdditionalTemplate;
